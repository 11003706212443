<template>
  <body-with-header title="فرم ثبت اطلاعات زمین با فایل" :loading="loading">
    <div class="main-card py-4">
      <CForm>
        <CRow class="mx-1">
          <CCol lg="12">
            <CForm class="vertical-g-20">
              <CRow>
                <CCol md="8">
                  <label>
                    نام انتخابی زمین
                    <span style="color: red">*</span>
                  </label>
                  <CInput
                    :lazy="false"
                    :value.sync="$v.form.title.$model"
                    :isValid="checkIfValid('title')"
                    autocomplete="given-name"
                    invalidFeedback="این فیلد اجباری است"
                  />
                </CCol>
                <CCol md="4">
                  <label>
                    تاریخ کشت
                    <span style="color: red">*</span>
                  </label>
                  <date-picker
                    color="var(--secondary)"
                    v-model="form.cultivationsDate"
                  ></date-picker>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="4" class="align-self-center mb-3 mb-md-2">
                  <span>
                    نوع محصول
                    <span style="color: red">*</span>
                  </span>
                  <v-select
                    dir="rtl"
                    :isValid="checkIfValid('productTypeId')"
                    v-model="selectedProductType"
                    :options="productTypesSource"
                    required
                    invalidFeedback="این فیلد اجباری است"
                    placeholder="انتخاب نوع محصول..."
                  ></v-select>
                </CCol>
                <CCol md="4" class="mb-3 mb-md-0">
                  <span>
                    نام محصول
                    <span style="color: red">*</span>
                  </span>
                  <v-select
                    dir="rtl"
                    :isValid="checkIfValid('productId')"
                    v-model="selectedProduct"
                    :options="productsSource"
                    required
                    invalidFeedback="این فیلد اجباری است"
                    placeholder="انتخاب محصول..."
                  ></v-select>
                </CCol>
                <CCol md="4" class="mb-3 mb-md-0">
                  <span>
                    نوع کشت
                    <span style="color: red">*</span>
                  </span>
                  <v-select
                    dir="rtl"
                    :isValid="checkIfValid('cultivationsType')"
                    v-model="selectedCultivationsType"
                    :options="cultivationsTypeSource"
                    required
                    invalidFeedback="این فیلد اجباری است"
                    placeholder="انتخاب نوع کشت..."
                  ></v-select>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="4" class="mb-3 mb-md-0">
                  <span>
                    کشور
                    <span style="color: red">*</span>
                  </span>
                  <v-select
                    dir="rtl"
                    v-model="selectedCountry"
                    :options="countriesSource"
                    required
                    invalidFeedback="این فیلد اجباری است"
                    placeholder="انتخاب کشور..."
                  />
                </CCol>
                <CCol md="4" class="mb-3 mb-md-0">
                  <span>
                    استان
                    <span style="color: red">*</span>
                  </span>
                  <v-select
                    dir="rtl"
                    :isValid="checkIfValid('stateId')"
                    v-model="selectedState"
                    :options="statesSource"
                    required
                    invalidFeedback="این فیلد اجباری است"
                    placeholder="انتخاب استان ها..."
                  ></v-select>
                </CCol>
                <CCol md="4" class="mb-3 mb-md-0">
                  <span>
                    شهرستان
                    <span style="color: red">*</span>
                  </span>
                  <v-select
                    dir="rtl"
                    :isValid="checkIfValid('cityId')"
                    v-model="selectedCity"
                    :options="cityOptions"
                    required
                    invalidFeedback="این فیلد اجباری است"
                    placeholder="انتخاب شهرستان ها..."
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <label>
                    بخش
                    <span style="color: red">*</span>
                  </label>
                  <CTextarea
                    :value.sync="$v.form.district.$model"
                    :isValid="checkIfValid('district')"
                    rows="3"
                    required
                    invalidFeedback="این فیلد اجباری است"
                  />
                </CCol>
                <CCol md="6">
                  <label>
                    روستا
                    <span style="color: red">*</span>
                  </label>
                  <CTextarea
                    :value.sync="$v.form.village.$model"
                    :isValid="checkIfValid('village')"
                    rows="3"
                    required
                    invalidFeedback="این فیلد اجباری است"
                  />
                </CCol>
              </CRow>
              <CRow class="mt-3">
                <CCol col="auto">
                  <input
                    type="file"
                    ref="KMLfile"
                    style="display: none"
                    @change="onFileChange"
                  />
                  <CButton
                    color="secondary"
                    class="y-center-g-5"
                    @click="$refs.KMLfile.click()"
                  >
                    <CIcon name="cil-file" size="lg" />
                    <span v-if="selectedFileName">{{ selectedFileName }}</span>
                    <span v-else>انتخاب فایل زمین</span>
                  </CButton>
                </CCol>
              </CRow>
              <form-action-buttons @ignoreForm="goLogin" @submitForm="submit" />
            </CForm>
          </CCol>
        </CRow>
      </CForm>
    </div>
  </body-with-header>
</template>

<script>
import { mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import cultivationsType from "../../store/data/enum/cultivationsType.json";
import { getCurrentUser } from "../../utils/index";
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import FormActionButtons from "../../components/formActionButtons.vue";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "farmAddKML",
  components: {
    FormActionButtons,
    BodyWithHeader,
    vSelect,
  },
  data() {
    return {
      successModal: false,
      form: this.getEmptyForm(),
      cultivationsTypeSource: cultivationsType,
      selectedCultivationsType: "",
      nodataList: [],

      countriesSource: [],
      selectedCountry: "",
      statesSource: [],
      selectedState: "",
      cityOptions: [],
      selectedCity: "",

      productTypesSource: [],
      selectedProductType: "",
      productsSource: [],
      selectedProduct: "",
      File: null,
      selectedFileName: "",
      loading: false,
    };
  },
  computed: {
    isValid() {
      return !this.$v.form.$invalid;
    },
  },
  mixins: [validationMixin],
  watch: {
    selectedProductType: function () {
      this.productsSource = [];
      this.selectedProduct = "";
      if (this.selectedProductType.id > 0) {
        this.getProductWithType(this.selectedProductType.id);
      }
    },
    selectedCountry: function () {
      this.statesSource = [];
      this.selectedState = "";
      this.cityOptions = [];
      this.selectedCity = "";

      if (this.selectedCountry.id > 0) {
        this.GetProvinceWithCountryId(this.selectedCountry.id);
      }
    },
    selectedState: function () {
      this.cityOptions = [];
      this.selectedCity = "";
      if (this.selectedState.id > 0) {
        this.GetCityWithProvinceId(this.selectedState.id);
      }
    },
  },
  validations: {
    form: {
      userType: {},
      userId: {},
      farmerId: {},
      title: {
        required,
      },
      cultivationsDate: {
        required,
      },
      productTypeId: {
        required,
      },
      productId: {
        required,
      },
      cultivationsType: {
        required,
      },
      stateId: {
        required,
      },
      cityId: {
        required,
      },
      district: {
        required,
      },
      village: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("farm", [
      "addFarm",
      "GetAllProductType",
      "GetProductWithType",
      "GetAllCountry",
      "GetProvinceWithCountry",
      "GetCityWithProvince",
    ]),
    reset() {
      this.$refs.form.resetValidation();
    },

    async submit() {
      let logedInUser = getCurrentUser();
      this.$v.form.userType.$model = logedInUser.type;
      this.$v.form.productTypeId.$model = this.selectedProductType.id;
      this.$v.form.productId.$model = this.selectedProduct.id;
      this.$v.form.cultivationsType.$model =
        this.selectedCultivationsType.value;
      this.$v.form.stateId.$model = this.selectedState.id;
      this.$v.form.cityId.$model = this.selectedCity.id;
      this.form.KMLfile = this.File;
      this.$v.form.farmerId.$model = null;
      this.$v.$touch();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$notify({
          title: "پیام سیستم",
          text: "اطلاعات وارد شده معتبر نیستند <br /><br />",
          type: "error",
        });
        return;
      } else {
        this.loading = true;
        const { form } = this;
        let result = await this.addFarm({ farm: form });
        this.loading = true;
        if (result.succeeded) {
          this.$notify({
            title: "پیام سیستم",
            text: "ثبت اطلاعات زمین انجام شد <br /><br />",
            type: "success",
          });
          this.$router.push({ name: "صفحه اصلی" });
        } else {
          this.loading = false;
          this.$notify({
            title: "خطت رخ داده",
            text: `${result.message} <br /><br />`,
            type: "error",
          });
        }
      }
    },

    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    validate() {
      this.$v.$touch();
    },
    goLogin() {
      // named route
      this.$router.push({ name: "نقشه های ماهواره ای زمین" });
    },
    getEmptyForm() {
      return {
        userType: "",
        title: "",
        cultivationsDate: "",
        productTypeId: "",
        productId: "",
        cultivationsType: "",
        stateId: "",
        cityId: "",
        village: "",
        district: "",
        farmerId: null,
        KMLfile: null,
      };
    },

    async getAllProductType() {
      var result = await this.GetAllProductType({ pageNumber: 0, pageSize: 0 });
      this.productTypesSource = result.map((item, id) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async getProductWithType(typeId) {
      var result = await this.GetProductWithType({ productTypeId: typeId });
      this.productsSource = result.map((item, id) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async getAllCountry() {
      var result = await this.GetAllCountry({ pageNumber: 0, pageSize: 0 });
      this.countriesSource = result.map((item, id) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async GetProvinceWithCountryId(countryId) {
      var result = await this.GetProvinceWithCountry({ countryId: countryId });
      this.statesSource = result.map((item, id) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async GetCityWithProvinceId(provinceId) {
      var result = await this.GetCityWithProvince({ stateId: provinceId });
      this.cityOptions = result.map((item, id) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.File = files[0];
      this.selectedFileName = this.File.name;
    },
    async fetchData() {
      await this.getAllProductType();
      await this.getAllCountry();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style>
/* Add your styles here */
</style>
